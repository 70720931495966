import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { environment } from "@environments/environment";
import { GenericResp } from "@interfaces/generic-resp";
import { languageData } from "@interfaces/utils";
import { BehaviorSubject, filter, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  private languageSubjectLocal!: BehaviorSubject<string>;

  private readonly languageSubj: BehaviorSubject<languageData[]> =
    new BehaviorSubject<languageData[]>([] as languageData[]);
  private readonly languagePreferredSubj: BehaviorSubject<string> =
    new BehaviorSubject<string>("");
  readonly language$ = this.languageSubj
    .asObservable()
    .pipe(filter((language: languageData[]) => !!language));
  readonly languagePreferredCode$ = this.languagePreferredSubj
    .asObservable()
    .pipe(filter((language: string) => !!language));

  get languageData(): languageData[] {
    return this.languageSubj.value;
  }

  set languageData(languageData: any) {
    this.languageSubj.next(languageData);
  }
  get languagePreferredCode(): string {
    return this.languagePreferredSubj.value;
  }

  set languagePreferredCode(languageData: string) {
    this.languagePreferredSubj.next(languageData);
  }
  private readonly backendApi = environment.backend.cyberlearn;

  constructor(
    public http: HttpClient,
    @Inject(LOCALE_ID) private localeId: string
  ) {
    this.languageSubjectLocal = new BehaviorSubject<string>(this.localeId);
  }
  get languageLocal$() {
    return this.languageSubjectLocal.asObservable();
  }

  getLanguages(): any {
    return this.http.get(`${this.backendApi}/api/neo/front/languages`);
  }

  getUserLanguages(): any {
    return this.http.get(`${this.backendApi}/api/v2/front/languages/user`);
  }

  getPreferredLanguage() {
    return this.http.get(
      `${this.backendApi}/api/neo/front/languages/user/PreferredLanguage`
    );
  }

  setUserPreferredLanguage(languageId: number): Observable<any> {
    return this.http.post<any>(
      `${this.backendApi}/api/neo/front/languages/user/preferred-language`,
      { language_id: languageId }
    );
  }
  setCompanyManagerPreferredLanguage(languageId: number): Observable<any> {
    return this.http.post<any>(
      `${this.backendApi}/api/neo/front/languages/company/preferred-language`,
      { language_id: languageId }
    );
  }
}
